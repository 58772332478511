import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { addNote } from '../../service';
import { formatDateWithTime } from '../../utils';
import { NextButtonIcon } from '../icons';
import Spinner from './spinner';
import { useTranslation } from 'react-i18next';

// 'id' = group or user id based on the 'type'
export default function Notes({ notes = [], type, id, handleAddedNote = () => {} }) {
  const { t } = useTranslation();
  const notesFieldRef = useRef(null);
  const [isSubmittingNote, setIsSubmittingNote] = useState(false);
  const [notesList, setNotesList] = useState([]);

  useEffect(() => {
    if (notesList.length !== notes.length) {
      setNotesList(notes);
    }
  }, [notes]);

  const handleAddNote = async () => {
    const fieldValue = notesFieldRef.current?.value;

    if (!fieldValue || isSubmittingNote) {
      return;
    }

    if (fieldValue && fieldValue.trim().length > 100) {
      toast.info('Please keep the characters less than 100.');
      return;
    }

    try {
      setIsSubmittingNote(true);

      const { data: addedNote } = await addNote({
        type,
        [type]: id,
        content: fieldValue
      });

      toast.success('Note added successfully');

      handleAddedNote(addedNote);

      // // Add note to notes list
      const allNotes = JSON.parse(JSON.stringify(notesList));

      allNotes.unshift({
        _id: addedNote._id,
        content: addedNote.content,
        updatedAt: addedNote.updatedAt
      });

      setNotesList(allNotes);

      notesFieldRef.current.value = '';
    } catch (error) {
      console.error('Failed to add a note: ', error);
      toast.error('Failed to add a note.');
    } finally {
      setIsSubmittingNote(false);
    }
  };
  return (
    <>
      <div className="p-0.5 bg-[#F8F8F8] rounded-xl relative">
        <textarea
          ref={notesFieldRef}
          id="notes"
          rows="2"
          className="px-4 py-2 w-full text-sm text-gray-900 bg-[#F8F8F8] border-0 focus:ring-0 focus:outline-none resize-none placeholder:text-black placeholder:font-semibold rounded-xl"
          placeholder={t('notes.placeholder')}></textarea>
        <button
          type="button"
          onClick={handleAddNote}
          className={clsx(
            `absolute right-2 bottom-2 flex items-center justify-center p-1 w-10 h-10 text-xs font-medium text-center text-white rounded-full focus:ring-0`
          )}>
          {isSubmittingNote ? <Spinner color="#000000" isSubmitting={true} /> : <NextButtonIcon />}
        </button>
      </div>

      {/* ALL NOTES */}
      {notesList?.length > 0 && (
        <div className="w-full rounded-xl mt-4 pr-6 overflow-y-auto max-h-[180px]">
          <ol className="border-s border-gray-200 ml-8 my-2">
            {notesList?.map((note) => (
              <li className="mb-4 ms-6" key={note._id}>
                <div className="items-center justify-between p-3 bg-[#EEEEEE] text-[#757575] border border-gray-200 rounded shadow-sm text-sm sm:flex">
                  {note.content}
                </div>
                <div className="my-2 flex items-center justify-between">
                  <span className="ml-1 mb-1 text-xs font-normal text-gray-400 sm:mb-0">
                    {note.updatedBy}
                  </span>
                  <div className="flex items-center justify-end gap-4">
                    <time className="mb-1 text-xs font-normal text-gray-400 sm:mb-0">
                      {formatDateWithTime(note.updatedAt)}
                    </time>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
      )}
    </>
  );
}
