import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

const ButtonPlatform = forwardRef(({ onClick, className, children, loading, ...props }, ref) => {
  const [profile] = useContext(ProfileContext);
  const { masterOrganization: { customConfigurations: { buttonRadius = '9999px' } = {} } = {} } =
    profile;

  return (
    <button
      ref={ref}
      {...props}
      className={clsx(
        'self-start text-lg font-bold px-6 py-2 bg-backgroundGrey text-textAndIcons flex items-center gap-2 shadow',
        className
      )}
      style={{
        borderRadius: buttonRadius
      }}
      disabled={loading}
      onClick={onClick}>
      {children}
      <Spinner isSubmitting={loading} />
    </button>
  );
});

export default ButtonPlatform;
