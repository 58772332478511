import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfileContext } from '../contexts/profileContext';
import useOrganizationColors from '../hooks/useOrganizationColors';
import {
  deleteGroup,
  disableGroup,
  getCompleteGroupDetails,
  getGroupNotes,
  getProfileDataForExport,
  updateGroupName
} from '../service';
import { calculatePercentage, getTranslation } from '../utils';
import { exportData, hexToRgba } from '../utils/utils';
import { EditableField, Notes, Popover } from './common';
import Button from './common/button';
import {
  ArchiveIcon,
  CancelIcon,
  EmailIcon,
  ExportIcon,
  HourGlassIcon,
  RightArrowFilledIcon,
  RightArrowIcon,
  UserAddIcon
} from './icons';
import MoveGroup from './moveGroup';

const GroupDetailsView = ({
  group,
  groupId, // pass only when no group details
  canManageProfiles = false,
  canManageGroups = false,
  showManageProfilesButton = true,
  onUpdate = () => {},
  onNameUpdate = () => {}
}) => {
  const { t, i18n } = useTranslation();
  const colors = useOrganizationColors();
  const [searchParams, setSearchParams] = useSearchParams();
  const [adminProfile] = useContext(ProfileContext);
  const [groupInfo, setGroupInfo] = useState({ ...group } || {});

  const { masterOrganization: { translationPrefix } = {} } = adminProfile;

  const [notes, setNotes] = useState([]);

  const getNotes = async () => {
    try {
      const { data: groupNotes } = await getGroupNotes(groupInfo?._id || groupId);

      setNotes(groupNotes);
    } catch (e) {
      toast.error('Failed to get group notes.');
      console.error('error:', e);
    }
  };

  const getGroupDetails = async () => {
    try {
      const { data } = await getCompleteGroupDetails(groupId);
      setGroupInfo(data);
    } catch (e) {
      toast.error('Failed to get group details.');
      console.error('error:', e);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  // component loaded from non-dashboard locations
  useEffect(() => {
    if (groupId) {
      getGroupDetails();
    }
  }, []);

  const handleNameUpdate = (nameUpdateDetails) => {
    if (groupId) {
      getGroupDetails();
    }
    onNameUpdate(nameUpdateDetails);
  };

  const handleGroupDisableClick = async () => {
    try {
      await disableGroup(groupInfo?._id);
      toast.success('Group disabled successfully.');
      onUpdate({ isOpen: false });
    } catch (error) {
      toast.error('Failed to disable group.');
      console.error('Failed to disable group.');
    }
  };

  const handleGroupDeleteClick = async () => {
    try {
      await deleteGroup(groupInfo?._id);
      toast.success('Group deleted successfully.');
      onUpdate({ isOpen: false });
    } catch (error) {
      toast.error('Failed to delete group.');
      console.error('Failed to delete group.');
    }
  };

  const groupViewActionItems = [
    { id: 'email-profiles', name: t('groupDetails.action.emailProfiles'), icon: EmailIcon },
    { id: 'export-data', name: t('groupDetails.action.exportData'), icon: ExportIcon }
  ];

  if (canManageGroups) {
    groupViewActionItems.unshift({
      id: 'move-group',
      name: t('groupDetails.action.moveGroup'),
      icon: RightArrowIcon,
      popover: <MoveGroup group={groupInfo} onSubmit={() => onUpdate({ isOpen: false })} />
    });
  }

  if (canManageProfiles) {
    groupViewActionItems.push(
      { id: 'team-members', name: t('groupDetails.action.teamMembers'), icon: UserAddIcon },
      {
        id: 'disable-store',
        name: t('groupDetails.action.disableAndStore'),
        icon: ArchiveIcon,
        popover: (
          <div className="px-4 py-2">
            <h4 className="font-semibold"> {t('groupDetails.confirm.disable.title')}</h4>
            <p className="text-sm my-2 text-secondary">
              {t('groupDetails.confirm.disable.description')}
            </p>
            <Button.ButtonFilled onClick={handleGroupDisableClick} isMini>
              {t('groupDetails.confirm.disable.submit')}
            </Button.ButtonFilled>
          </div>
        )
      },
      {
        id: 'delete',
        name: t('groupDetails.action.delete'),
        icon: CancelIcon,
        popover: (
          <div className="px-4 py-2">
            <h4 className="font-semibold">{t('groupDetails.confirm.delete.title')}</h4>
            <p className="text-sm my-2 text-secondary">
              {t('groupDetails.confirm.delete.description')}
            </p>
            <Button.ButtonFilled onClick={handleGroupDeleteClick} isMini>
              {t('groupDetails.confirm.delete.submit')}
            </Button.ButtonFilled>
          </div>
        )
      }
    );
  }

  const handleGroupViewActionClick = async (actionItemId) => {
    const selectedGroupId = groupInfo?._id;
    switch (actionItemId) {
      case 'email-profiles':
      case 'team-members':
        onUpdate({ screen: actionItemId });
        if (actionItemId === 'email-profiles') {
          searchParams.set('groupDetailsView', 'emailProfiles');
        }
        if (actionItemId === 'team-members') {
          searchParams.set('groupDetailsView', 'teamMembers');
        }
        setSearchParams(searchParams);
        break;

      case 'export-data':
        try {
          const { data } = await getProfileDataForExport('groupId', selectedGroupId, i18n.language);
          const exportHeaders = [{ key: 'email', name: 'Email' }, ...data.headers].map(
            (header) => ({
              key: header.key,
              name: getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label
            })
          );
          exportData(exportHeaders, data.profiles);
        } catch (error) {
          toast.error('Failed to export data.');
          console.error('Failed to export data: ', error);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="w-full p-6">
      <div className="flex justify-between gap-6">
        <div className="w-[60%] relative">
          <Badge colors={colors} text={groupInfo?.profileType?.name || groupInfo?.profileType} />
          <EditableField
            isEditable={canManageGroups}
            text={groupInfo.name}
            id={groupInfo._id}
            label={t('group.groupNameField')}
            submitFunction={updateGroupName}
            onUpdate={handleNameUpdate}
            fontSize="text-2xl"
          />
          <p className="text-secondary">
            In <span className="underline">{groupInfo?.groupName}</span>
          </p>
          {showManageProfilesButton && (
            <Link
              to={`/${groupInfo?._id}/profiles`}
              className="inline-block my-6 px-6 py-3 text-lg font-semibold rounded-full bg-backgroundGrey">
              {t('groupDetails.manageProfiles')}
            </Link>
          )}
          <div className="w-full text-lg font-medium my-10">
            <div className="flex items-center justify-between">
              <span>{t('groupDetails.notStarted')}</span>
              <span>
                {groupInfo?.notStarted}/
                {groupInfo.notStarted + groupInfo.inProgress + groupInfo.validated}
              </span>
            </div>
            <div className="flex items-center justify-between gap-3 my-6 text-sm">
              <RightArrowFilledIcon />
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <span>{t('groupDetails.sentForValidation')}</span>
                  <span className="text-secondary">
                    ({groupInfo?.validationRequested}/
                    {groupInfo.notStarted + groupInfo.inProgress + groupInfo.validated})
                  </span>
                </div>
                <div className="w-full bg-[#D9D9D9] rounded-full h-2.5 mt-2">
                  <div
                    className="bg-[#515151] h-2.5 rounded-full"
                    style={{
                      width: `${calculatePercentage(
                        groupInfo?.validationRequested,
                        groupInfo.notStarted + groupInfo.inProgress + groupInfo.validated
                      )}%`
                    }}></div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between gap-3 my-6 text-sm">
              <HourGlassIcon />
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <span>{t('groupDetails.validated')}</span>
                  <span className="text-secondary">
                    ({groupInfo?.validated}/
                    {groupInfo.notStarted + groupInfo.inProgress + groupInfo.validated})
                  </span>
                </div>
                <div className="w-full bg-[#D9D9D9] rounded-full h-2.5 mt-2">
                  <div
                    className="bg-[#515151] h-2.5 rounded-full"
                    style={{
                      width: `${calculatePercentage(
                        groupInfo?.validated,
                        groupInfo?.totalProfiles
                      )}%`
                    }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="text-tertiary space-y-2">
          {groupViewActionItems.map((actionItem) => (
            <li key={actionItem.id}>
              {actionItem.popover ? (
                <Popover
                  button={
                    <button
                      type="button"
                      className="flex items-center gap-2 rounded hover:bg-orange-100 px-1 cursor-pointer">
                      <actionItem.icon /> <span>{actionItem.name}</span>
                    </button>
                  }>
                  {actionItem.popover}
                </Popover>
              ) : (
                <ActionItemLayout
                  icon={actionItem.icon}
                  name={actionItem.name}
                  onClick={() => handleGroupViewActionClick(actionItem.id)}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
      <Notes notes={notes} type="group" id={groupInfo?._id} />
    </div>
  );
};

export default GroupDetailsView;

// Component for the common action item layout
const ActionItemLayout = ({ icon: Icon, name, onClick }) => (
  <span
    onClick={onClick}
    className="flex items-center gap-2 rounded hover:bg-orange-100 px-1 cursor-pointer">
    <Icon /> <span>{name}</span>
  </span>
);

const Badge = ({ colors = {}, text }) => {
  return (
    <span
      className="inline-block px-2 py-1 mb-1.5 rounded-full text-xs absolute -top-7"
      style={{
        backgroundColor: hexToRgba(colors.secondaryVariant, colors.intensitySecondary)
      }}>
      {text}
    </span>
  );
};
