import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getActiveColumns,
  getProfileDataForExport,
  getProfileDataForExportAll
} from '../../service';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import ButtonPlatform from './button/buttonPlatform';
import Loading from './loading';
import Button from './button';
import { exportAllData, exportData } from '../../utils/utils';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const ExportPopup = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();

  const [columns, setColumns] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [exportingColumns, setExportingColumns] = useState({});
  const [exportingSelectedColumns, setExportingSelectedColumns] = useState({});

  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      abortControllerRef.current = new AbortController();
      (async () => {
        setIsLoading(true);
        try {
          const { data: columnsList } = await getActiveColumns();
          setColumns(columnsList);
          const initialCheckedState = columnsList.reduce((acc, column) => {
            acc[column._id] = true;
            return acc;
          }, {});
          setCheckedItems(initialCheckedState);
        } catch (e) {
          toast.error('Failed to get columns.');
          console.error('Failed to get columns:', e);
        } finally {
          setIsLoading(false);
          setExportingSelectedColumns(false);
        }
      })();
    } else {
      cancelOngoingRequests();
    }

    return () => {
      cancelOngoingRequests();
    };
  }, [isOpen]);

  const cancelOngoingRequests = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  const handleColumnExport = useCallback(
    async (columnName, columnId) => {
      if (exportingColumns[columnId]) return;

      setExportingColumns((prev) => ({ ...prev, [columnId]: 'loading' }));
      try {
        const { data: exportList } = await getProfileDataForExport(
          'groupColumnId',
          columnId,
          i18n.language,
          abortControllerRef.current.signal
        );
        exportData(
          [{ key: 'email', name: 'Email' }, ...exportList.headers],
          exportList.profiles,
          columnName
        );
        setExportingColumns((prev) => ({ ...prev, [columnId]: 'success' }));
        setTimeout(() => {
          setExportingColumns((prev) => ({ ...prev, [columnId]: null }));
        }, 3000);
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log('Request was cancelled');
        } else {
          console.error('Failed to export data:', e);
          toast.error(t('exportFailed'));
        }
        setExportingColumns((prev) => ({ ...prev, [columnId]: null }));
      }
    },
    [i18n.language, t, exportingColumns]
  );

  if (!isOpen) return null;

  const handleCheckboxChange = (columnId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [columnId]: !prev[columnId]
    }));
  };

  const handleAllCheckboxChange = () => {
    const allChecked = Object.values(checkedItems).every(Boolean);
    const newCheckedState = columns.reduce((acc, column) => {
      acc[column._id] = !allChecked;
      return acc;
    }, {});
    setCheckedItems(newCheckedState);
  };

  const handleExport = async () => {
    if (exportingSelectedColumns) return;
    const checkedIds = Object.entries(checkedItems)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => id);

    try {
      setExportingSelectedColumns(true);
      const { data: exportItems } = await getProfileDataForExportAll(
        i18n.language,
        checkedIds,
        abortControllerRef.current.signal
      );
      exportAllData(exportItems, t, i18n.language);
      onClose();
    } catch (error) {
      if (error.name === 'CanceledError') {
        console.log('Request was cancelled');
      } else {
        toast.error('Failed to export data');
        console.error('Failed to export data: ', error);
      }
    } finally {
      setExportingSelectedColumns(false);
    }
  };

  const handleClose = () => {
    cancelOngoingRequests();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[80vh] overflow-hidden flex flex-col">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold mb-2">{t(`exportDataPopup.heading`)}</h2>
          <p className="text-gray-600">{t(`exportDataPopup.subheading`)}</p>
        </div>
        <div className="flex-1 overflow-y-auto p-6 max-h-[650px]">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loading loading={true} />
            </div>
          ) : (
            <>
              <div className="mb-4">
                <label className="flex items-center space-x-2 cursor-pointer w-fit">
                  <input
                    type="checkbox"
                    checked={Object.values(checkedItems).every(Boolean)}
                    onChange={handleAllCheckboxChange}
                    className="form-checkbox h-5 w-5 text-slate-600 transition duration-150 ease-in-out accent-black"
                  />
                  <span className="text-lg font-medium">
                    {t('exportDataPopup.selectAllCheckboxLabel')}
                  </span>
                </label>
              </div>
              <ul className="space-y-2">
                {columns.map(({ name: columnName, _id: columnId }) => (
                  <li
                    key={columnId}
                    className={clsx(
                      'border rounded-lg overflow-hidden transition-all duration-200 ease-in-out',
                      checkedItems[columnId] && 'bg-slate-200 border-zinc-200'
                    )}>
                    <label className="flex items-center p-4 cursor-pointer w-full">
                      <input
                        type="checkbox"
                        checked={checkedItems[columnId] || false}
                        onChange={() => handleCheckboxChange(columnId)}
                        className="form-checkbox h-5 w-5 text-zinc-600 transition duration-150 ease-in-out accent-black"
                      />
                      <span className={clsx('ml-3 font-medium flex-grow text-gray-700')}>
                        {columnName}
                      </span>
                      <ButtonPlatform
                        onClick={(e) => {
                          e.stopPropagation();
                          handleColumnExport(columnName, columnId);
                        }}
                        disabled={exportingColumns[columnId] === 'loading'}
                        className={`text-sm px-3 py-1 rounded flex items-center justify-center min-w-[100px] ${
                          exportingColumns[columnId] === 'success'
                            ? ' text-teal-600 shadow-sm shadow-teal-600'
                            : ' hover:bg-gray-200'
                        } ${exportingColumns[columnId] !== 'loading' && 'py-3'}`}>
                        {exportingColumns[columnId] === 'loading' ? (
                          <Loading loading={true} size="small" />
                        ) : exportingColumns[columnId] === 'success' ? (
                          <CheckCircleIcon className="h-5 w-5" />
                        ) : (
                          t('exportDataPopup.columnExportBtn')
                        )}
                      </ButtonPlatform>
                    </label>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="p-6 border-t border-gray-200 flex justify-end space-x-4">
          <Button.ButtonOutlined onClick={handleClose}>
            {t('exportDataPopup.cancelBtn')}
          </Button.ButtonOutlined>
          <Button.ButtonFilled onClick={handleExport} disabled={exportingSelectedColumns}>
            <Loading loading={exportingSelectedColumns} />
            {t('exportDataPopup.exportSelectedBtn')}
          </Button.ButtonFilled>
        </div>
      </div>
    </div>
  );
};

export default ExportPopup;
