/* eslint-disable no-debugger */
import * as Yup from 'yup';
import { translations } from './errorTranslations';
import userRoles from '../data/roles';

const availableLanguages = ['en', 'fr', 'de', 'it'];
let usedLanguage = 'en';

const getUsedlanguage = () => {
  const fetdhedLang = localStorage.getItem('lang');
  if (fetdhedLang && availableLanguages.includes(fetdhedLang)) usedLanguage = fetdhedLang;
  return usedLanguage;
};

const getTranslation = (errTranslations, schema, property) => {
  try {
    const selectedSchema = errTranslations[getUsedlanguage()][schema];

    let selectedValue;

    if (typeof property === 'string' && property.includes('.')) {
      const nestedValue = property.split('.').reduce((currentObject, key) => {
        return currentObject ? currentObject[key] : 'error';
      }, selectedSchema);
      selectedValue = nestedValue;
    } else {
      selectedValue = selectedSchema[property];
    }

    return selectedValue;
  } catch (error) {
    // console.error(error);
    return 'This field is required';
  }
};

export const LoginSchema = Yup.object({
  email: Yup.string()
    .email()
    .required(getTranslation(translations, 'genericFields', 'email'))
    .matches(
      /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/,
      getTranslation(translations, 'genericFields', 'validEmail')
    )
});

export const championDataSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  familyName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  birthYear: Yup.number()
    .required('Champ requis')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  city: Yup.string().required('Champ requis').max(50, 'Trop long'),
  postcode: Yup.string().required('Champ requis').max(8, 'Code Trop long'),
  bestApprentice: Yup.object().required('Champ requis'),
  bestWorker: Yup.object().required('Champ requis'),
  natFinalsYear: Yup.number()
    .required('Champ requis')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  natFinalsRank: Yup.object().required('Champ requis'),
  euroSkillsYear: Yup.number('Invalid date')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide')
    .optional(),
  natFinalsRegion: Yup.object().required('Champ requis').typeError('Champ requis'),
  trainingAndDiploma1: Yup.string().max(90, '90 caractères maximum').required('Champ requis'),
  trainingAndDiploma2: Yup.string().max(90, '90 caractères maximum'),
  worldSkillsProfession: Yup.object().required('Champ requis').typeError('Champ requis'),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide')
    .optional(),
  currentCompanyCityDept: Yup.string().max(50, 'Trop long').optional(),
  currentJobName: Yup.string().optional(''),
  gender: Yup.object().required('Champ requis'),
  facebookAddress: Yup.string().optional().url(),
  instagramAddress: Yup.string().optional().url(),
  tiktokAddress: Yup.string().optional().url()
});

export const abilympicsDataSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  familyName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  birthYear: Yup.number()
    .required('Champ requis')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  abilympicsProfession: Yup.object().required('Champ requis').typeError('Champ requis')
});

export const competitorDataSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  familyName: Yup.string().required('Champ requis').max(40, 'Trop long').min(2, 'Too short'),
  birthYear: Yup.number()
    .required('Champ requis')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  city: Yup.string().required('Champ requis').max(50, 'Trop long'),
  postcode: Yup.string().required('Champ requis').max(8, 'Code Trop long'),

  natFinalsRegion: Yup.object().required('Champ requis').typeError('Champ requis'),
  worldSkillsProfession: Yup.object().required('Champ requis').typeError('Champ requis'),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide')
    .optional()
    .nullable(),
  currentCompanyCityDept: Yup.string().max(50, 'Trop long').optional().nullable(),
  currentJobName: Yup.string().optional('').nullable()
});

export const chChampionDataSchema = Yup.object().shape({
  firstName: Yup.string().max(40, 'Trop lang').min(2, 'Zu kurz').optional(),
  familyName: Yup.string().max(40, 'Trop lang').min(2, 'Zu kurz').optional(),
  birthday: Yup.date()
    .max(new Date(), 'ungültiges Jahr')
    .min(new Date().getFullYear() - 100, 'ungültiges Jahr')
    .optional(),
  city: Yup.string().max(50, 'Trop lang'),
  postcode: Yup.string().max(8, 'Code Trop lang'),
  euroSkillsYear: Yup.number('Ungültiges Datum')
    .min(new Date().getFullYear() - 100, 'ungültiges Jahr')
    .max(new Date().getFullYear(), 'ungültiges Jahr')
    .optional(),
  trainingAndDiploma1: Yup.string().max(90, '90 maximale Zeichen').optional(),
  trainingAndDiploma2: Yup.string().max(90, '90 maximale Zeichen').optional(),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'ungültiges Jahr')
    .max(new Date().getFullYear(), 'ungültiges Jahr')
    .optional(),
  currentCompanyCityDept: Yup.string().max(50, 'Trop lang').optional(),
  currentJobName: Yup.string().optional(''),
  gender: Yup.object().optional(),
  facebookAddress: Yup.string().optional().url(),
  instagramAddress: Yup.string().optional().url(),
  tiktokAddress: Yup.string().optional().url(),
  region: Yup.object().optional(),
  mobileNumber: Yup.string().matches(/^\+?\d(?:[\d\s]*)$/, 'Ungültige Telefonnummer'),
  montherLanguage: Yup.string().optional(),
  otherLanguage: Yup.string().optional(),
  specialJobTitle: Yup.object().optional(),
  profession: Yup.object().optional(),
  swissChampionYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'ungültiges Jahr')
    .max(new Date().getFullYear(), 'ungültiges Jahr')
    .optional(),
  swissChampionRank: Yup.object().optional(),
  associationProfession: Yup.object().optional(),
  vocationSchool: Yup.object().optional(),
  functions: Yup.array().optional(),
  trainingAndDiploma3: Yup.string().max(90, '90 maximale Zeichen'),
  recentProfession1: Yup.string().max(90, '90 maximale Zeichen'),
  recentProfession2: Yup.string().nullable().max(90, '90 maximale Zeichen'),
  recentProfession3: Yup.string().nullable().max(90, '90 maximale Zeichen'),
  linkedinAddress: Yup.string().optional().url(),
  consent: Yup.boolean().optional().nullable(),
  participationType: Yup.object().optional().nullable()
});

export const createOrganizationSchema = Yup.object({
  name: Yup.string()
    .min(2)
    .max(25)
    .required(getTranslation(translations, 'createOrganizationSchema', 'name')),
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  image: Yup.string().nullable().optional(),
  profileType: Yup.array()
    .of(Yup.string())
    .min(1, getTranslation(translations, 'genericFields', 'profileType'))
    .required(getTranslation(translations, 'genericFields', 'profileType')),
  competitorProfileType: Yup.string().when('profileType', (profileType, schema) => {
    return profileType === userRoles.COMPETITOR
      ? schema.required(getTranslation(translations, 'genericFields', 'competitorProfileType'))
      : schema;
  })
});

export const updateOrganizationAdminProfileSchema = Yup.object({
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  image: Yup.string().nullable().optional(),
  language: Yup.string().required(
    getTranslation(translations, 'updateOrganizationAdminProfileSchema', 'language')
  )
});

export const updateAdminProfileSchema = Yup.object({
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  language: Yup.string().required(
    getTranslation(translations, 'updateAdminProfileSchema', 'language')
  )
});

export const profileTypeEditSchema = Yup.object({
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  profiles: Yup.array().of(Yup.string()),
  profileType: Yup.string().required(getTranslation(translations, 'genericFields', 'profileType'))
});

export const profileTypeCreateSchema = Yup.object({
  creationType: Yup.string(),
  email: Yup.string().when('creationType', (creationType, schema) => {
    return creationType === 'single'
      ? schema.required(getTranslation(translations, 'genericFields', 'email'))
      : schema;
  }),
  profiles: Yup.array()
    .of(Yup.string())
    .when('creationType', (creationType, schema) => {
      return creationType === 'multiple'
        ? schema.required(getTranslation(translations, 'profileTypeCreateSchema', 'profiles'))
        : schema;
    }),
  bulkProfiles: Yup.object()
    .shape({})
    .when('creationType', (creationType, schema) => {
      return creationType === 'bulk'
        ? schema.required(getTranslation(translations, 'profileTypeCreateSchema', 'profiles'))
        : schema;
    }),
  profileType: Yup.string().required(getTranslation(translations, 'genericFields', 'profileType'))
});

export const moveProfilesSchema = Yup.object({
  organization: Yup.string().required(
    getTranslation(translations, 'moveProfilesSchema', 'organization')
  ),
  profiles: Yup.array().of(
    Yup.object({
      _id: Yup.string(),
      email: Yup.string().email()
    })
  )
});

export const subOrganizationAdminAccountSchema = Yup.object({
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  permissions: Yup.array()
    .nullable()
    .of(Yup.string())
    .min(1, getTranslation(translations, 'subOrganizationAdminAccountSchema', 'permissions.min'))
    .required(
      getTranslation(translations, 'subOrganizationAdminAccountSchema', 'permissions.required')
    )
});

export const subAdminAccountSchema = Yup.object({
  email: Yup.string().email().required(getTranslation(translations, 'genericFields', 'email')),
  systemAssignedOrganization: Yup.array()
    .of(Yup.string())
    .nullable()
    .min(1, getTranslation(translations, 'subAdminAccountSchema', 'systemAssignedOrganization.min'))
    .required(
      getTranslation(translations, 'subAdminAccountSchema', 'systemAssignedOrganization.required')
    ),
  permissions: Yup.array()
    .nullable()
    .of(Yup.string())
    .min(1, getTranslation(translations, 'subAdminAccountSchema', 'permissions.min'))
    .required(getTranslation(translations, 'subAdminAccountSchema', 'permissions.required'))
});

export const profileFilterSchema = Yup.object().shape({
  birthYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  city: Yup.string().max(50, 'Trop long'),
  postcode: Yup.string().max(8, 'Code Trop long'),
  bestApprentice: Yup.object(),
  bestWorker: Yup.object(),
  natFinalsYear: Yup.number()

    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide'),
  natFinalsRank: Yup.object(),
  euroSkillsYear: Yup.number('Invalid date')
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide')
    .optional(),
  natFinalsRegion: Yup.object().typeError('Champ requis'),
  worldSkillsProfession: Yup.object().typeError('Champ requis'),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'Année invalide')
    .max(new Date().getFullYear(), 'Année invalide')
    .optional(),
  gender: Yup.object()
});

export const addNewGroupColumnSchema = Yup.object().shape({
  columnName: Yup.string()
    .required('columnName is required')
    .max(40, 'Too long')
    .min(2, 'Too short'),
  profileType: Yup.string().required('profileType is required')
});

export const addNewGroupSchema = Yup.object().shape({
  groupName: Yup.string().required('groupName is required').max(40, 'Too long').min(2, 'Too short')
});

export const refuseGroupProfilesSchema = Yup.object({
  notes: Yup.string().required('notes is required').min('10', 'Too Short').max('200', 'Too long')
});

export const moveGroupProfileSchema = Yup.object().shape({
  group: Yup.string().required('group id is required'),
  profiles: Yup.array()
    .of(
      Yup.object({
        _id: Yup.string().required(),
        email: Yup.string().email()
      })
    )
    .required('profiles is required')
});

export const moveGroupSchema = Yup.object().shape({
  column: Yup.string().required('column id is required')
});

export const addNewTeamMemberSchema = Yup.object().shape({
  email: Yup.string().email().required('Field is required'),
  role: Yup.string().required('Field is required')
});

export const emailNotificationSchema = Yup.object().shape({
  emailSubject: Yup.string().required('emailSubject is required').min(2, 'too short'),
  emailMessage: Yup.string().required('emailMessage is required').min(2, 'too short'),
  emailLanguage: Yup.string()
    .oneOf(['en', 'fr', 'de', 'it'], 'Invalid language')
    .required('emailLanguage is required'),
  notificationFor: Yup.string()
    .oneOf(['not-started', 'in-progress', 'validation-requested', 'validated'], 'Invalid type')
    .required('notificationFor is required')
});

export const editableFieldSchema = Yup.object().shape({
  fieldText: Yup.string().required('fieldText is required').min(3, 'too short')
});

export const genericFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(40, getTranslation('form.firstName.max'))
    .min(2, getTranslation('form.firstName.min')),
  familyName: Yup.string()
    .max(40, getTranslation('form.familyName.max'))
    .min(2, getTranslation('form.familyName.min')),
  birthYear: Yup.number()
    .min(new Date().getFullYear() - 100, getTranslation('form.birthYear.min'))
    .max(new Date().getFullYear(), getTranslation('form.birthYear.max')),
  city: Yup.string().max(50, getTranslation('form.city.max')),
  postcode: Yup.string().max(8, getTranslation('form.postcode.max')),
  bestApprentice: Yup.object().optional(),
  bestWorker: Yup.object().optional(),
  natFinalsYear: Yup.number()
    .min(new Date().getFullYear() - 100, getTranslation('form.natFinalsYear.min'))
    .max(new Date().getFullYear(), getTranslation('form.natFinalsYear.max')),
  natFinalsRank: Yup.object().optional(),
  euroSkillsYear: Yup.number(getTranslation('form.euroSkillsYear.number'))
    .min(new Date().getFullYear() - 100, getTranslation('form.euroSkillsYear.min'))
    .max(new Date().getFullYear(), getTranslation('form.euroSkillsYear.max'))
    .optional(),
  natFinalsRegion: Yup.object().optional(),
  trainingAndDiploma1: Yup.string().max(90, getTranslation('form.trainingAndDiploma1.max')),
  trainingAndDiploma2: Yup.string()
    .max(90, getTranslation('form.trainingAndDiploma2.max'))
    .optional(),
  worldSkillsProfession: Yup.object().optional(),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, getTranslation('form.worldSkillsYear.min'))
    .max(new Date().getFullYear(), getTranslation('form.worldSkillsYear.max'))
    .optional(),
  currentCompanyCityDept: Yup.string()
    .max(50, getTranslation('form.currentCompanyCityDept.max'))
    .optional(),
  currentJobName: Yup.string().optional(''),
  gender: Yup.object(),
  facebookAddress: Yup.string().optional().url(getTranslation('form.facebookAddress.url')),
  instagramAddress: Yup.string().optional().url(getTranslation('form.instagramAddress.url')),
  tiktokAddress: Yup.string().optional().url(getTranslation('form.tiktokAddress.url')),
  region: Yup.object().typeError(getTranslation('form.region.required')),
  mobileNumber: Yup.string().matches(
    /^\+?\d(?:[\d\s]*)$/,
    getTranslation('form.mobileNumber.matches')
  ),
  montherLanguage: Yup.string(),
  otherLanguage: Yup.string().optional(),
  specialJobTitle: Yup.object().typeError(getTranslation('form.specialJobTitle.required')),
  profession: Yup.object(),
  swissChampionYear: Yup.number()
    .min(new Date().getFullYear() - 100, getTranslation('form.swissChampionYear.min'))
    .max(new Date().getFullYear(), getTranslation('form.swissChampionYear.max'))
    .optional(),
  swissChampionRank: Yup.object().optional(),
  associationProfession: Yup.object().typeError(
    getTranslation('form.associationProfession.required')
  ),
  vocationSchool: Yup.object().typeError(getTranslation('form.vocationSchool.required')),
  functions: Yup.array(),
  trainingAndDiploma3: Yup.string().max(90, getTranslation('form.trainingAndDiploma3.max')),
  recentProfession1: Yup.string().max(90, getTranslation('form.recentProfession1.max')),
  recentProfession2: Yup.string().nullable().max(90, getTranslation('form.recentProfession2.max')),
  recentProfession3: Yup.string().nullable().max(90, getTranslation('form.recentProfession3.max')),
  linkedinAddress: Yup.string().optional().url(getTranslation('form.linkedinAddress.url')),
  participationType: Yup.object().optional().nullable(),
  abilympicsProfession: Yup.object().optional(),
  consent: Yup.boolean().optional().nullable()
});

export const addProfilesSchema = Yup.object().shape({
  type: Yup.string()
    .required(getTranslation(translations, 'genericFields', 'required'))
    .oneOf(['single-profile', 'multiple-profiles', 'bulk-profiles']),
  emailAddress: Yup.string().when('type', {
    is: 'single-profile',
    then: Yup.string()
      .email(getTranslation(translations, 'genericFields', 'validEmail'))
      .required(getTranslation(translations, 'genericFields', 'email')),
    otherwise: Yup.string().nullable()
  }),
  profiles: Yup.array().when('type', {
    is: 'multiple-profiles',
    then: Yup.array()
      .of(
        Yup.string()
          .email(getTranslation(translations, 'genericFields', 'validEmail'))
          .required(getTranslation(translations, 'genericFields', 'email'))
      )
      .min(1, getTranslation(translations, 'profileTypeCreateSchema', 'profiles'))
      .required(getTranslation(translations, 'profileTypeCreateSchema', 'profiles')),
    otherwise: Yup.array().nullable()
  }),
  bulkProfiles: Yup.object().when('type', {
    is: 'bulk-profiles',
    then: Yup.object().test(
      'non-empty',
      getTranslation(translations, 'bulkProfiles', 'emptyFile'),
      function (value) {
        return value && Object.keys(value).length > 0;
      }
    ),
    otherwise: Yup.object().nullable()
  })
});
