import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProfileContext } from '../contexts/profileContext';
import { deleteGroupProfiles, disableGroupProfiles } from '../service';
import { getTranslation } from '../utils';
import { exportData } from '../utils/utils';
import { Popover } from './common';
import Button from './common/button';
import {
  ArchiveIcon,
  BackspaceIcon,
  CrossIcon,
  ExportIcon,
  MeatballsMenu,
  SortIcon
} from './icons';
import MoveProfile from './moveProfile';

const TableBulkActions = ({
  colors = {},
  groupId,
  groupName = '',
  selectedRows = {},
  allData = [],
  headers = [],
  onSelectClick = () => {},
  onSubmit = () => {}
}) => {
  const { t } = useTranslation();
  const [selectedUI, setSelectedUI] = useState('main');

  const [showBulkActionsButton, setShowBulkActionsButton] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const [adminProfile] = useContext(ProfileContext);

  const { masterOrganization: { translationPrefix } = {} } = adminProfile;

  const options = [
    // {
    //   id: 'approve',
    //   name: t('tableBulkActions.approve'),
    //   icon: PlusIcon
    // },
    // {
    //   id: 'refuse',
    //   name: t('tableBulkActions.refuse'),
    //   icon: CancelIcon
    // },
    {
      id: 'move',
      name: t('tableBulkActions.move'),
      icon: SortIcon
    },
    {
      id: 'export-data',
      name: t('tableBulkActions.export'),
      icon: ExportIcon
    },
    {
      id: 'disable',
      name: t('tableBulkActions.disable'),
      icon: ArchiveIcon
    },
    {
      id: 'delete',
      name: t('tableBulkActions.delete'),
      icon: BackspaceIcon
    }
  ];

  useEffect(() => {
    const selectedIndexes = Object.keys(selectedRows)
      .map((key) => parseInt(key))
      .filter((index) => selectedRows[index]);

    // retrieve the items at the selected indexes
    const selectedItems = selectedIndexes
      .map((index) => allData[index])
      .map((index) => ({ _id: index._id, email: index.email }));

    setProfiles(selectedItems);
  }, [selectedRows]);

  useEffect(() => {
    onSelectClick(showBulkActionsButton);
  }, [showBulkActionsButton]);

  const handleOptionClick = async (option) => {
    if (!profiles?.length) {
      toast.info('Please select some profiles to perform these actions.');
      return;
    }

    if (!groupId) {
      toast.error('Group info is not valid to perform these actions.');
      return;
    }

    // if (option.id === 'approve') {
    //   try {
    //     await approveGroupProfiles(groupId, selectedItemIds);
    //     toast.success('Profiles approved successfully.');
    //   } catch (error) {
    //     console.error('Failed to approve profiles', error);
    //     toast.error('Failed to approve profiles.');
    //   }
    // } else if (option.id === 'refuse') {
    //   try {
    //     await refuseGroupProfiles(groupId, { profileIds: selectedItemIds });
    //     toast.success('Profiles refused successfully.');
    //   } catch (error) {
    //     console.error('Failed to refuse profiles', error);
    //     toast.error('Failed to refuse profiles.');
    //   }
    // }

    const selectedItemIds = profiles.map((profile) => profile._id);
    const selectedItemEmails = profiles.map((profile) => profile.email);

    if (option.id === 'move') {
      setSelectedUI('move-profiles');
    } else if (option.id === 'delete') {
      try {
        await deleteGroupProfiles(groupId, selectedItemIds, selectedItemEmails);
        toast.success('Profiles deleted successfully.');
        setShowBulkActionsButton(false);
        onSubmit();
      } catch (error) {
        console.error('Failed to delete profiles', error);
        toast.error('Failed to delete profiles.');
      }
    } else if (option.id === 'disable') {
      try {
        await disableGroupProfiles(groupId, selectedItemIds, selectedItemEmails);
        toast.success('Profiles disabled successfully.');
        setShowBulkActionsButton(false);
        onSubmit();
      } catch (error) {
        console.error('Failed to disabled profiles', error);
        toast.error('Failed to disabled profiles.');
      }
    } else if (option.id === 'export-data') {
      const selectedData = allData.filter((item) => selectedItemIds.includes(item._id));
      const exportHeaders = [{ key: 'email', name: 'Email' }, ...headers].map((header) => ({
        key: header.key,
        name: getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label
      }));
      exportData(exportHeaders, selectedData);
    }
  };

  return (
    <>
      {showBulkActionsButton ? (
        <Button.ButtonFilled
          type="button"
          onClick={() => setShowBulkActionsButton(!showBulkActionsButton)}>
          {t('tableBulkActions.button')}
        </Button.ButtonFilled>
      ) : (
        <Button.ButtonOutlined
          type="button"
          onClick={() => setShowBulkActionsButton(!showBulkActionsButton)}>
          {t('tableBulkActions.button')}
        </Button.ButtonOutlined>
      )}
      {showBulkActionsButton && (
        <Popover
          position="bottom left"
          size={selectedUI === 'move-profiles' ? 'normal' : 'sm_normal'}
          isOpen={isPopoverOpen}
          onClose={() => setSelectedUI('main')}
          button={
            <button>
              <MeatballsMenu className="cursor-pointer rounded hover:bg-gray-100" />
            </button>
          }>
          <div>
            {selectedUI !== 'move-profiles' && (
              <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg flex items-center justify-end">
                <CrossIcon onClick={() => setShowBulkActionsButton(false)} />
              </header>
            )}
            <main className="max-h-[450px] overflow-y-auto">
              {selectedUI === 'move-profiles' && (
                <MoveProfile
                  groupId={groupId}
                  groupName={groupName}
                  profiles={profiles}
                  onSubmit={onSubmit}
                />
              )}
              {selectedUI === 'main' && (
                <ul className="p-6">
                  {options.map((option, optionIndex) => (
                    <li key={option.key} className="mb-4">
                      <Button.ButtonPlatform
                        onClick={() => {
                          handleOptionClick(option);
                        }}>
                        <option.icon />
                        {option.name}
                      </Button.ButtonPlatform>
                      {/* <button
                        type="button"
                        onClick={() => {
                          handleOptionClick(option);
                        }}
                        className="px-4 py-1.5 flex gap-2 rounded-full bg-inputFields hover:bg-black hover:text-white">
                        <option.icon />
                        {option.name}
                      </button> */}
                    </li>
                  ))}
                </ul>
              )}
            </main>
          </div>
        </Popover>
      )}
    </>
  );
};

export default TableBulkActions;
