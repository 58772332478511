import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { editableFieldSchema } from '../../utils/schema';
import Spinner from './spinner';
import TextInput from './textInput';

export default function EditableField({
  id,
  label = 'Enter value',
  text,
  isEditable = true,
  fontSize = 'text-xl',
  onUpdate = () => {},
  submitFunction = async () => {}
}) {
  const { t } = useTranslation();
  const [isEditActive, setIsEditActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: { fieldText: text },
    validationSchema: editableFieldSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { fieldText } = values;
        await submitFunction(id, { fieldText });
        toast.success('Updated successfully.');
        setIsEditActive(false);
        onUpdate({ id, fieldText });
      } catch (error) {
        console.error('Failed to update field: ', error);
        toast.error('Failed to update.');
      } finally {
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('fieldText', text);
  }, [text]);

  const handleEditCloseClick = () => {
    formik.resetForm();
    setIsEditActive(false);
  };

  return isEditActive ? (
    <form onSubmit={formik.handleSubmit} className="w-[90%] relative">
      <TextInput
        name="fieldText"
        label={label}
        value={formik.values.fieldText}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.fieldText}
      />
      <span className="flex items-center gap-1 absolute -top-1 -right-1">
        {isLoading ? (
          <Spinner isSubmitting={isLoading} color="#000" />
        ) : (
          <button type="submit">
            <CheckCircleIcon className="w-5 cursor-pointer hover:fill-green-300 transition-all" />
          </button>
        )}
        <XCircleIcon
          className={clsx('w-5 transition-all', {
            'cursor-not-allowed': isLoading,
            'cursor-pointer hover:fill-red-300': !isLoading
          })}
          onClick={isLoading ? null : () => handleEditCloseClick()}
        />
      </span>
    </form>
  ) : (
    <h3
      className={clsx(
        'font-bold text-gray-900 max-w-[80%] break-words truncate cursor-pointer',
        fontSize
      )}
      onClick={isEditable ? () => setIsEditActive(true) : null}>
      {text}
    </h3>
  );
}
