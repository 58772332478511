import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  GENERIC_NOTIFICATION_TYPE,
  GENERIC_NOTIFICATION_TYPE_COLOR,
  IMAGE_URL
} from '../../data/constants';
import { getNotifications } from '../../service';
import { formatDateWithDays } from '../../utils';
import { NotificationIcon } from '../icons';
import Popover from './popover';
import Loading from './loading';
import Button from '../common/button';
import { Tab } from '@headlessui/react';

const Notifications = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState({});
  const [activeTab, setActiveTab] = useState(GENERIC_NOTIFICATION_TYPE.VALIDATION_REQUESTED);

  const fetchNotifications = async (type) => {
    try {
      setIsLoading(true);
      setNotifications({}); // Clear previous notifications
      const { data } = await getNotifications(type);
      setNotifications(data);
    } catch (error) {
      toast.error('Failed to get notifications');
      console.error('Failed to get notifications: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(activeTab);
  }, [activeTab]);

  const tabItems = [
    {
      key: GENERIC_NOTIFICATION_TYPE.VALIDATION_REQUESTED,
      label: t('notification.tabs.validationRequested'),
      bgColor: 'bg-[#9747FF33]',
      textColor: 'text-[#7A30D9]'
    },
    {
      key: GENERIC_NOTIFICATION_TYPE.PROFILE_UPDATED,
      label: t('notification.tabs.profileUpdated'),
      bgColor: 'bg-[#51C94F33]',
      textColor: 'text-[#3A9E38]'
    },
    {
      key: GENERIC_NOTIFICATION_TYPE.NOTE_ADDED,
      label: t('notification.tabs.noteAdded'),
      bgColor: 'bg-[#FFAD3333]',
      textColor: 'text-[#D98C1A]'
    }
  ];

  return (
    <Popover
      position="bottom center"
      size="normal"
      onOpen={() => fetchNotifications(activeTab)}
      button={
        <button
          type="button"
          className={clsx('rounded-full font-semibold text-lg flex items-center justify-center')}>
          <NotificationIcon className="cursor-pointer" />
        </button>
      }>
      <div>
        <header className="bg-[#F8F8F8] px-6 py-4 rounded-t-lg">
          <h4
            className="font-semibold mb-4"
            dangerouslySetInnerHTML={{ __html: t('notification.title') }}
          />
          <Tab.Group onChange={(index) => setActiveTab(tabItems[index].key)}>
            <Tab.List className="flex space-x-1 rounded-xl bg-white p-1 shadow">
              {tabItems.map((item) => (
                <Tab
                  key={item.key}
                  className={({ selected }) =>
                    clsx(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
                      selected
                        ? `${item.bgColor} ${item.textColor}`
                        : 'text-gray-500 hover:text-gray-700'
                    )
                  }>
                  {item.label}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </header>
        <main className="max-h-[450px] overflow-y-auto p-6">
          <div className="flex justify-center items-center h-full w-full">
            <Loading loading={isLoading} />
          </div>
          <ul>
            <div className="max-w-lg mx-auto">
              {Object.keys(notifications)?.map((notificationDate, index) => {
                const allNotificationsByDate = notifications[notificationDate];
                return (
                  <div key={notificationDate}>
                    <p className="font-semibold text-textAndIcons mb-4">
                      {formatDateWithDays(notificationDate)}
                    </p>
                    {allNotificationsByDate.map((notification) => (
                      <NotificationItem key={notification._id} {...notification} />
                    ))}
                  </div>
                );
              })}
            </div>
          </ul>
        </main>
      </div>
    </Popover>
  );
};

const NotificationItem = ({
  userId,
  groupId,
  date,
  image,
  name,
  groupName,
  profileType,
  type,
  updates,
  note
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notificationColor = GENERIC_NOTIFICATION_TYPE_COLOR[type];

  const profileImage = image
    ? `${IMAGE_URL}profile-images/${image}`
    : '/images/default-user-icon.png';

  const handleNotificationView = () => {
    navigate(`/${groupId}/profiles/${userId}`);
  };

  return (
    <div className="bg-backgroundGrey rounded-xl shadow p-4 mb-4">
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          <div className="flex-shrink-0 relative">
            <img className="h-12 w-12 rounded-full" src={profileImage} alt="" />
            <div
              className="w-3 h-3 rounded-full border border-backgroundGrey absolute top-0 right-0"
              style={{ backgroundColor: notificationColor }}
            />
          </div>
          <div className="ml-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{name}</h3>
            <p className="text-sm text-gray-500">{groupName}</p>
            <p className="text-sm text-gray-500">{profileType}</p>
            <div className="mt-4">
              {updates?.length > 0 && (
                <div>
                  <p
                    className="mb-2 text-sm"
                    dangerouslySetInnerHTML={{ __html: t('notification.updatesHeading') }}
                  />
                  {updates.map((update, index) => (
                    <span
                      key={index}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700 mr-2 mb-2">
                      {t(`notification.details.${update}`)}
                    </span>
                  ))}
                </div>
              )}
              {note && (
                <div className="text-sm">
                  <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: t('notification.noteHeading') }}
                  />
                  <span className="bg-white rounded px-2 py-1  w-full break-words">{note}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button.ButtonFilled onClick={handleNotificationView} isMini>
          {t('notification.buttonText')}
        </Button.ButtonFilled>
      </div>
    </div>
  );
};

export default Notifications;
