import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

const ButtonOutlined = forwardRef(
  ({ onClick, className, children, loading, spinnerColor = '#ffffff', ...props }, ref) => {
    const [profile] = useContext(ProfileContext);
    const {
      masterOrganization: { customConfigurations: { colors, buttonRadius = '9999px' } = {} } = {}
    } = profile;

    return (
      <button
        {...props}
        ref={ref}
        className={clsx(
          `px-6 py-2 text-lg font-bold flex items-center justify-center border-2 disabled:opacity-60`,
          className
        )}
        style={{
          borderRadius: buttonRadius,
          ...(loading ? {} : { borderColor: colors?.primary, color: colors?.primary })
        }}
        disabled={loading}
        onClick={onClick}>
        <Spinner isSubmitting={loading} className="mr-2" color={spinnerColor} />
        {children}
      </button>
    );
  }
);

export default ButtonOutlined;
