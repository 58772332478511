import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { ProfileContext } from '../../../contexts/profileContext';
import { Spinner } from '../../common';

const ButtonFilled = forwardRef(
  (
    {
      onClick,
      className,
      children,
      loading,
      isMini = false,
      spinnerColor = '#ffffff',
      disabled,
      ...props
    },
    ref
  ) => {
    const [profile] = useContext(ProfileContext);
    const {
      masterOrganization: { customConfigurations: { colors, buttonRadius = '9999px' } = {} } = {}
    } = profile;

    return (
      <button
        ref={ref}
        {...props}
        className={clsx(
          `text-white font-bold flex items-center justify-center border-2 disabled:opacity-60 hover:opacity-95`,
          className,
          isMini ? 'px-4 py-1 text-md' : 'px-6 py-2 text-lg'
        )}
        style={{
          backgroundColor: colors?.primary,
          borderRadius: buttonRadius,
          borderColor: colors?.primary
        }}
        disabled={loading || disabled}
        onClick={onClick}>
        <Spinner isSubmitting={loading} className="mr-2" color={spinnerColor} /> {children}
      </button>
    );
  }
);

export default ButtonFilled;
